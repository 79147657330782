@font-face {
  font-family: 'Roboto-Black';
  src: url('../fonts/Roboto/Roboto-Black.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto/Roboto-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/Roboto/Roboto-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/Roboto/Roboto-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../fonts/Roboto/Roboto-Thin.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica/Helvetica.ttc');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Bold';
  src: url('../fonts/Helvetica/HelveticaNowText-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Medium';
  src: url('../fonts/Helvetica/HelveticaNowText-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText-Regular';
  src: url('../fonts/Helvetica/HelveticaNowText-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trueno-Bold';
  src: url('../fonts/Trueno/TruenoBd.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Trueno-Light';
  src: url('../fonts/Trueno/TruenoLt.otf');
  font-weight: normal;
  font-style: normal;
}
