@import '../global/main';

.modal {
  position: absolute;
  top: -7em;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  @include flex-center;
  background: inherit;

  &__window {
    position: fixed;
    z-Index: 999999;
    overflow-y: auto;
    width: 424px;
    height: fit-content;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: $white;
    opacity: 1;
    border-radius: 8px;
  }

  &__spinner {
    position: fixed;
    z-Index: 999999;
    width: 424px;
    height: fit-content;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 1;
    border-radius: 8px;
  }

  &__background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99999;
    background: $primary-01;
    opacity: .3;
  }
}


