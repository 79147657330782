@mixin sweet-animation($time) {
  animation: sweep #{$time} ease-in-out;

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-left: -10px
    }
    100% {
      opacity: 1;
      margin-left: 0px
    }
  }
}

@mixin transition($time) {
  transition: all $time ease-in-out;
}
