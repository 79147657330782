$white: #FFFFFF;
$gray-01: #4E6375;
$gray-02: #68839A;
$gray-03: #8EA2B3;
$gray-04: #E3E8EC;
$gray-05: #F6F6F6;

$primary-01: #02223E;
$primary-02: #055DAA;
$primary-03: #077BE0;
$primary-04: #2796F8;
$primary-05: #72BBFA;
$primary-06: #A1D1FC;
$primary-07: #D0E8FD;

$color-01: #7BDFF2;
$color-02: #CCF8FF;
$color-03: #FFF3CC;
$color-04: #D9F7F3;
$color-05: #69A8FF;
$color-06: #F2B5D4;
$color-07: #E1E0FF;
$color-08: #F7D6E0;

$success-01: #238735;
$success-02: #D1F6D8;

$info-01: #537EF4;
$info-02: #C8D6FA;

$warning-01: #FFD726;
$warning-02: #FFEB93;

$error-01: #D12E26;
$error-02: #F7C8C6;
$error-03: #CC1D21;


$small: 576px;
$medium: 768px;
$large: 992px;
$extra-large: 1200px;
$full: 1400px;
$HD: 1600px;
$FHD: 1900px;
