@import "src/assets/styles/global/main";

.admin {
  padding: 1rem 3rem;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 36px;
    }

    p {
      color: $gray-01;
      font-family: 'HelveticaNowText-Regular', sans-serif;
      font-size: 16px;
    }

    div:last-of-type {
      display: flex;
      gap: 1rem;
      justify-self: end;

      button:first-of-type {
        @include button-reset;
        width: 159px;
        height: 40px;
        background: $white;
        border: 2px solid $primary-01;
        border-radius: 4px;
      }

      button:last-of-type {
        @include button-reset;
        width: 228px;
        height: 40px;
        background: $primary-01;
        color: $white;
        border-radius: 4px;
      }
    }
  }

  &__content-delete {
    @include flex-col();
    margin: 0 auto;
    min-width: 180px;
    max-width: 70vw;

    &__sub {
      color: $primary-01;
      text-align: center;
      height: 34px;
      font-family: 'HelveticaNowText-Medium';
      font-size: 20px;
      line-height: 24px;
    }

    &__search {
      @include flex-row-self-end;
      margin-top: 1rem;
    }
  }

  &__result {
    &__card {
      border-radius: 8px;
      background-color: $white;

      &__title {
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: $primary-01;
        padding: 2rem 1.5rem;
      }

      &__items {
        @include flex-col;
        padding: .5rem;

        div {
          @include flex-between;
          border-radius: 8px;
          padding: 1.5rem 2rem;

          &:hover, focus {
            background-color: $primary-07;
            @include transition(.8s);
          }

          &:hover .area {
            color: $primary-02;
          }

          &:focus .area {
            color: $primary-03;
          }
        }

        .i-metrics {
          color: $gray-01;
          background-color: $gray-04;
          padding: .4rem;
          border-radius: 100%;
          mix-blend-mode: multiply;
          font-size: 16px;
        }

        .delete {
          cursor: pointer;

          .i-trash {
            color: $primary-01;
            font-size: 16px;
          }

          &:hover .i-trash {
            color: $primary-02;
          }

          &:focus .i-trash {
            color: $primary-03;
            opacity: .9;
            transition: opacity .1s;
          }

          &:hover {
            color: $primary-02;
            text-decoration: underline;
          }

          &:focus {
            color: $primary-03;
            opacity: .9;
            text-decoration: underline;
          }
        }

        &__no-areas {
          padding-left: 1rem;
        }

      }

    }

    &__spinner {
      width: 100%;
      @include flex-center;
    }
  }

  .box-actions {
    @include flex-center;
    margin: 2rem auto;
    gap: 2rem;
    width: 200px;

    .exit {
      &:hover {
        color: $primary-02;
        text-decoration: underline;
        cursor: pointer;
      }

      &:focus {
        color: $primary-03;
        text-decoration: underline;
      }
    }
  }

  &__feedback-to-add {
    @include flex;
    gap: 2rem;
    margin: 2rem 0;

    h2 {
      font-family: 'HelveticaNowText-Bold';
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
      color: $primary-01;
    }

    p {
      display: block;
      margin-bottom: 2rem;
    }
  }

  &__msg-delete {
    padding: 51px 3rem 2rem;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $primary-01;

    p:last-of-type {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
    }

    &__actions {
      padding: 1rem;

      span {
        margin-right: 1rem;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;

        &:hover {
          text-decoration: underline;
        }
      }

      button {
        width: 120px !important;

      }
    }
  }
}
