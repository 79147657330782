@import "src/assets/styles/global/main";

.dashboards-home {
  padding: 1rem 3rem;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;


    h1 {
      font-size: 36px;
    }

    p {
      color: $gray-01;
      font-family: 'HelveticaNowText-Regular', sans-serif;
      font-size: 16px;
    }


    &__actions {
      display: flex;
      gap: 1rem;
      justify-self: end;

      button:first-of-type {
        @include button-reset;
        width: 159px;
        height: 40px;
        background: $white;
        border: 2px solid $primary-01;
        border-radius: 4px;
      }

      button:last-of-type {
        @include button-reset;
        width: 228px;
        height: 40px;
        background: $primary-01;
        color: $white;
        border-radius: 4px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include medium {
      flex-wrap: nowrap;
      gap: 3rem;
      margin: 2rem 0;
    }

    &__image {
      margin: 0 auto;
      max-width: 300px;

      img {
        height: auto;
        margin-bottom: 1rem;
        width: 100%;
      }

      p {
        font-size: 24px;
        line-height: 35px;
        font-family: 'HelveticaNowText', sans-serif;
        text-align: center;
      }
    }

    &__grid-card {
      display: grid;
      gap: 2rem;
      flex-direction: row;
      width: 100%;
      cursor: pointer;

      @include medium {
        grid-template-columns: 1fr;
      }

      @include large {
        grid-template-columns: repeat(2, 1fr);
      }

      @include extra-large {
        grid-template-columns: repeat(3, 1fr);
        width: auto;
      }
    }
  }
}

.card-dashboard {
  padding: 22px 16px 18px;
  border-radius: 9px;
  width: 317px;
  height: 175px;

  &__top {
    display: flex;
    justify-content: space-between;

    span {
      color: $gray-01;
      font-size: 12px;
      font-family: 'HelveticaNowText-Regular', sans-serif;
      font-weight: 500;
    }

    button {
      svg {
        color: $gray-01;
        background-color: $gray-04;
        padding: .5rem;
        border-radius: 50%;
        mix-blend-mode: multiply;
      }
    }
  }

  &__bottom {
    h1 {
      font-size: 24px;
      font-family: 'HelveticaNowText-Bold', sans-serif;
      margin: 26px 0 10px;
    }

    p {
      color: $gray-01;
      font-size: 12px;
      font-family: 'HelveticaNowText-Regular', sans-serif;
      font-weight: 500;
    }
  }

}

.color-01 {
  background-color: $color-01;
}

.color-02 {
  background-color: $color-02;
}

.color-03 {
  background-color: $color-03;
}

.color-04 {
  background-color: $color-04;
}

.color-05 {
  background-color: $color-05;
}

.color-06 {
  background-color: $color-06;
}

.color-07 {
  background-color: $color-07;
}

.main-power-bi {
  padding: 2rem 3rem;
  display: flex;
  align-items: center;
}

.not-dashboard {
  padding-top: 20vh;
  text-align: center;
}
