@import '../global/main';

.button_inner.primary {
  border: 2px solid $primary-01;
  background: #FFFFFF;
}

.button_inner.secondary {
  background-color: $primary-01;
  border: 2px solid $primary-01;

  span.t, i.l {
    color: $white;

    &:hover {
      color: $white;
    }
  }


  &:hover {
    background-color: $primary-01;
  }
}

.button {
  margin: auto;
  text-align: Center;
  height: 360px;
  width: 200px;
  transform: translateY(-50%);
  position: absolute;

  &_inner {
    @include button-reset;
    border-radius: 4px;
    width: 200px;
    padding: .3rem 0 .5rem;
    left: 0;
    right: 0;
    top: 50%;
    margin: auto;
    font-weight: 100;
    font-size: 12px;
    cursor: pointer;
    text-align: Center;
    transition: all .3s, box-shadow .2s, transform .2s .2s;

    span.t {
      position: relative;
      opacity: 1;
      left: -10px;
      transition: left .4s .1s;
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $primary-01;
    }

    i.l {
      position: relative;
      left: 0;
      top: 20px;
      font-size: 18px;
      opacity: 0;
      transition: left .3s 0s, top .3s 0s, opacity .3s 0s;
    }

    &:hover {
      color: #2C3940;
      background: white;
      box-shadow: 0px 17px 18px -14px rgba(0, 0, 0, 0.08);

      span.t {
        left: 5px;
        transition: left .4s;
      }

      i.l {
        top: 3px;
        opacity: 1;
        transition: left .3s 0s, top .3s .1s, opacity .3s .1s;
      }
    }
  }
}
