@import "src/assets/styles/global/main";

.admin {
  padding: 1rem 3rem;

  &__content-add {
    @include flex-col();

    &__sub {
      color: $primary-01;
      text-align: center;
      font-family: 'HelveticaNowText-Medium';
      font-size: 20px;
      @include flex-center;
    }

    &__desc {
      color: $gray-01;
      text-align: center;
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin: 0 0 2rem;
      @include flex-center;
    }

    &__actions {
      @include flex-center;
      margin: 2rem auto;
      gap: 2rem;
      width: 200px;

      .exit {
        &:hover {
          color: $primary-02;
          text-decoration: underline;
          cursor: pointer;
        }

        &:focus {
          color: $primary-03;
          text-decoration: underline;
        }
      }
    }

    &__second-step-title {
      font-family: 'HelveticaNowText-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin: 51px 0 8px
    }

    &__options {
      span {
        font-family: 'HelveticaNowText-Medium';
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-right: 1rem;

        &:hover {
          text-decoration: underline;
          color: $primary-02;
          cursor: pointer;
        }
      }
    }

    &__areas {
      display: grid;
      gap: 2rem;
      flex-direction: row;
      width: 100%;
      cursor: pointer;
      justify-content: center;
      margin-top: 2rem;
      height: 40vh;
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbar;

      &__card {
        padding: 20px 16px 19px;
        background-color: $white;
        width: 317px;
        height: 100px;
        @include flex-between;

        .text {
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          text-align: left;

          word-wrap: break-word;
          width: 70%;
        }

        .icon-metrics {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          background: $gray-04;
          color: $gray-01;
          font-size: 25px;
          @include flex-center;
        }

        &:hover, focus {
          background-color: $primary-07;
          @include transition(.8s);
        }

        &:hover .area {
          color: $primary-02;
        }

        &:focus .area {
          color: $primary-03;
        }
      }

      @include medium {
        grid-template-columns: repeat(2, auto);
      }

      @include extra-large {
        grid-template-columns: repeat(3, auto);
      }

      @include full {
        grid-template-columns: repeat(4, minmax(auto, 317px) );
      }

    }

    &__spinner {
      margin-top: 2rem;
      height: 40vh;
    }

    &__result {
      &__card {
        border-radius: 8px;
        background-color: $white;

        &__title {
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          color: $primary-01;
          padding: 2rem 1.5rem;
        }

        &__items {
          @include flex-col;
          padding: .5rem;

          div {
            @include flex-between;
            border-radius: 8px;
            padding: 1.5rem 2rem;

            &:hover, focus {
              background-color: $primary-07;
              @include transition(.8s);
            }

            &:hover .area {
              color: $primary-02;
            }

            &:focus .area {
              color: $primary-03;
            }
          }

          .i-metrics {
            color: $gray-01;
            background-color: $gray-04;
            padding: .4rem;
            border-radius: 100%;
            mix-blend-mode: multiply;
            font-size: 16px;
          }

          .delete {
            cursor: pointer;

            .i-trash {
              color: $primary-01;
              font-size: 16px;
            }

            &:hover .i-trash {
              color: $primary-02;
            }

            &:focus .i-trash {
              color: $primary-03;
              opacity: .9;
              transition: opacity .1s;
            }

            &:hover {
              color: $primary-02;
              text-decoration: underline;
            }

            &:focus {
              color: $primary-03;
              opacity: .9;
              text-decoration: underline;
            }
          }

          &__no-areas {
            padding-left: 1rem;
          }

        }

      }

      &__spinner {
        width: 100%;
        @include flex-center;
      }
    }

    &__feedback-to-add {
      @include flex;
      gap: 2rem;
      margin: 2rem 0;

      h2 {
        font-family: 'HelveticaNowText-Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 44px;
        color: $primary-01;
      }

      p {
        display: block;
        margin-bottom: 2rem;
      }
    }

    &__msg-delete {
      padding: 51px 3rem 2rem;
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $primary-01;

      p:last-of-type {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
      }

      &__actions {
        padding: 1rem;

        span {
          margin-right: 1rem;
          cursor: pointer;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;

          &:hover {
            text-decoration: underline;
          }
        }

        button {
          width: 120px !important;

        }
      }
    }

    .box-actions {
      position: relative;
      top: 0;
      padding: 1rem;

      div {
        position: fixed;
        @include flex-center;
        margin: 2rem auto;
        gap: 2rem;
        width: 200px;

        .exit {
          &:hover {
            color: $primary-02;
            text-decoration: underline;
            cursor: pointer;
          }

          &:focus {
            color: $primary-03;
            text-decoration: underline;
          }
        }
      }
    }

    &__error {
      @include flex-center;
      position: relative;
      bottom: 0;

      &__msg {
        position: fixed;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $error-01;
      }
    }
  }

  .stepper {
    margin: 0 auto;

    div {
      position: relative;
      @include flex-center;
      gap: 1rem;

      .bridge:before {
        content: '';
        position: absolute;
        left: 54px;
        height: 4px;
        width: 3rem;
        background-color: $gray-02;
      }

      .i {
        font-size: 3.5rem;
        position: relative;
        color: $gray-02;

        span {
          position: absolute;
          color: $white;
          font-family: 'HelveticaNowText-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
        }
      }

      &__first {
        position: absolute;
      }

      &__second {
        position: absolute;

      }

      .active {
        color: $primary-01;
      }

      .check {
        color: $success-01;
      }
    }
  }
}

