@import "variables";

@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Scrollbar */

@mixin scrollbar() {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: $gray-05;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: $gray-05;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-01;
    border-radius: 16px;
    border: 1px solid $white;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}


/* Flex mixins */

@mixin flex() {
  display: flex;
}

@mixin flex-col() {
  display: flex;
  flex-direction: column;
}

@mixin flex-vert-center() {
  display: flex;
  align-items: center;
}

@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-start() {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-evenly() {
  display: flex;
  justify-content: space-evenly;
}

@mixin flex-col-self-end() {
  align-self: flex-end;
}

@mixin flex-col-self-start() {
  align-self: flex-start;
}

@mixin flex-row-self-end() {
  align-self: flex-end;
}



