@import '../global/main';

.autocomplete-wrap {
  position: relative;

  &__input {
    display: flex;
    position: relative;

    &__tags {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      min-height: 48px;
      padding: 0 8px;
      border: 2px solid $gray-02;
      width: 100%;
      border-radius: 8px;

      &:focus-within {
        border: 2px solid $primary-03;
        box-shadow: 0px 2px 4px rgba(2, 34, 62, 0.25);
      }

      &:hover {
        border: 2px solid $primary-02;
      }

      input {
        flex: 1;
        border: none;
        height: 46px;
        font-size: 14px;
        padding: 1rem .5rem;
        background: inherit;

        &:focus {
          outline: transparent;
        }
      }

      #tags {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 8px 0 0 0;
      }

      .tag {
        width: auto;
        height: 32px;
        padding: 0 8px;
        font-size: 18px;
        list-style: none;
        border-radius: 4px;
        margin: 0 8px 8px 0;
        background: $primary-07;
        @include flex-center;
        gap: .5rem;

        .tag-title {
          color: $primary-02;
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
        }

        .wrap-icon {
          position: relative;
          @include flex-center;
          padding: .5rem;
          cursor: pointer;

          &__close {
            position: absolute;
            background: $primary-03;
            opacity: 40%;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            @include flex-center;
          }

          i {
            position: absolute;
            font-size: .5rem;
            color: $primary-02;
            z-index: 9;
          }
        }
      }

      .tag-error {
        background: $error-02;

        .tag-title {
          color: $error-01;
        }

        .wrap-icon {
          &__close {
            background: $error-01;
          }

          i {
            color: $error-01;
          }
        }
      }

      @media screen and (max-width: 567px) {
        .tags-input {
          width: calc(100vw - 32px);
        }
      }
    }

    &__icons {
      position: absolute;
      right: 0;
      margin: 1rem;
    }
  }

  &__options {
    margin-top: 5px;
    background-color: white;
    padding: .5rem;
    box-shadow: 0px 2px 4px rgba(2, 34, 62, 0.25);
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: 9;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      color: black;
    }

    p {
      cursor: pointer;
    }
  }

  #clearIcon {
    cursor: pointer;
  }
}

.error-tag {
  border: 2px solid $error-03;
  border-radius: 8px;
}

.error-msg {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: $error-01;
}
