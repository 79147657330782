@import "assets/styles/global/main";
@import url('./assets/icons/icomoon/style.css');

body {
  background-color: $gray-05;
}

* {
  box-sizing: border-box;
}


iframe {
  border: 0;
}

.embed-container {
  height: 100vh;
  width: 100%;
}


.amplify-icon > svg {
  color: #00214a !important;
}

.amplify-button {
  content: "Iniciar Sesión" !important;
  background-color: #00214a !important;
  color: #FFFFFF !important;
}

.amplify-button:hover {
  background-color: #001530 !important;
  color: #FFFFFF !important;
}

.amplify-button:active {
  background-color: #20416C !important;
  color: #FFFFFF !important;
}

.amplify-button:disabled {
  background-color: #20416C !important;
  color: #FFFFFF !important;
  content: "Iniciar Sesión" !important;
}

.amplify-field__show-password {
  background-color: #FFFFFF !important;
}

.amplify-field__show-password:hover {
  background-color: #f7f7f7 !important;
}

.amplify-field__show-password:active {
  background-color: #f7f7f7 !important;
}

.amplify-field__show-password:disabled {
  background-color: #f7f7f7 !important;
}

#radix-id-4392885473-22-trigger-1 {
  display: none;
}

.amplify-tabs-item {
  color: #00214A !important;
  background-color: #00000000 !important;
  transition-property: none;
}

.amplify-tabs-item:hover {
  color: #001530 !important;
}

.amplify-tabs-item:active {
  color: #20416C !important;
  border-color: #00214A !important;
}

.amplify-tabs-item[data-state=active] {
  border-color: #00214A !important;
}

div[data-amplify-footer] {
  display: none !important;
}

.amplify-flex.amplify-tabs [aria-selected="false"] {
  display: none !important;
}

.amplify-flex.amplify-tabs [aria-selected="true"] {
  cursor: revert;
  text-indent: -9999px !important;
  line-height: 0 !important;
}

.amplify-flex.amplify-tabs [aria-selected="true"]::after {
  content: "Bienvenido";
  text-indent: 0 !important;
  display: block !important;
  line-height: initial !important;
}

.amplify-flex .amplify-button[data-variation="primary"] {
  text-indent: -9999px !important;
  line-height: 0 !important;
}

.amplify-flex .amplify-button[data-variation="primary"]::after {
  content: "Ingreso";
  text-indent: 0 !important;
  display: block !important;
  line-height: initial !important;
}

.css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #35DDFF !important;
}

.amplify-alert[data-variation="error"] .amplify-alert__icon svg {
  color: #f65454 !important;
}

.amplify-alert[data-variation="error"] .amplify-alert__dismiss svg {
  color: white !important;
}

