@import '../global/main';

.message {
  @include flex;
  gap: 1rem;
  padding: .5rem 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.info {
  background-color: $info-02;

  i {
    color: $primary-01;
  }
}

.danger {
  background-color: $error-02;
  color: $error-01;
}

.success {
  background-color: $success-02;
  color: $success-01;
}

.warning {
  background-color: $warning-02;
  color: $warning-01;
}
