@import '../global/main';

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ .checkmark {
    background-color: $white;
  }

  & input:checked ~ .checkmark {
    background-color: $primary-03;
    border: 2px solid $primary-03;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 2px solid $primary-01;
    border-radius: 4px;

    &:hover {
      border: 2px solid $primary-02;
      opacity: 1;
      transition: .5s opacity;
    }

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      width: 6px;
      height: 12px;
      top: 0;
      right: 0;
      left: 6px;
      bottom: 0;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.container input:checked ~ .checkmark:after {
  display: block;
}
