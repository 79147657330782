@import "src/assets/styles/global/main";

.main {
  display: grid;
  grid-template-rows: 12vh 88vh;
  max-width: 1440px;
  margin: auto;

  &__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 3rem;


    &__logo {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        font-family: 'HelveticaNowText-Regular', sans-serif;
        font-size: 25px;
      }
    }

    &__breadcrumb {
      display: none;
    }

    @include medium {
      &__breadcrumb {
        display: flex;
        flex-direction: row;

        ul {
          list-style: none;
          display: flex;
          flex-direction: row;

          li {
            cursor: pointer;
          }

          li:last-of-type {
            text-decoration: underline;
            cursor: default;
          }
        }

        a {
          font-family: 'HelveticaNowText-Regular', sans-serif;
          color: $primary-01;
        }
      }
    }

    &__logout {
      display: flex;

      button {
        font-family: 'HelveticaNowText-Medium';
        padding: 20px;
        text-transform: none !important;
        font-size: 12px;
        color: $primary-01;

        svg {
          margin-left: .5rem;
          font-size: 20px;
        }
      }
    }
  }
}


